<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <span v-html="record.content"></span>
        </div>

        <div class="col-lg-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus && ! isLoadingBlade'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12">
            <div class="right">
                <base-button type="success" @click="showNextPage()">
                    Continue
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'DiasporaReqPage',
        props: ['displayBioData'],
        data() {
            return {
                record: {},
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getSiteParameter']),
            showNextPage() {// go to next page
                return this.displayBioData(true);
            },
            getParameter() {// get Parameter
                let name = 'diaspora-guidelines';
                this.getSiteParameter({'name': name}).then((res) => {
                    this.isLoadingBlade = false;
                    if(res) {
                        this.record = res;
                    } else {
                        this.showErrorMsg('No Portal Guidelines Yet!');
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorMsg('Network Failure!, reload page and try again...');
                });
            },
            showErrorMsg(msg) {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = msg;
            },
        },
        created() {
            this.getParameter();// get Parameter
        },
    }
</script>