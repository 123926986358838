<template>
    <div>
        <div>
            <hr class="my-4">
            <h2
                class="mb-0 text-uppercase text-center"
                v-if="application && application.has_payment"
            >
                Make New Payment for
                <em class="text-danger">
                    {{ application.name }}
                </em>
            </h2>
        </div>

        <div class="container ct-example-row" v-if="application && application.has_payment">
            <div class="row">
                <div class="col-lg-12">
                    <ul class="list-unstyled">
                        <li>
                            <hr class="my-4">
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.name }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ totalAmount | formatPrice }} {{ currency }}
                                </em>
                            </h3>

                            <div v-if="application && application.has_payment && application.has_payment.has_breakdowns
                                        && application.has_payment.has_breakdowns.length > 0"
                            >
                                <hr class="my-4">
                                <h1 class="text-center">
                                    Payment Breakdowns
                                </h1>
                                <hr class="my-4">
                                <h3
                                    v-for="breakdown in application.has_payment.has_breakdowns"
                                    :key="breakdown.id"
                                >
                                    {{ breakdown.name }}: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ breakdown.amount | formatPrice }} {{ currency }}
                                        </em>
                                    <br>
                                </h3>

                                <!-- <h3
                                    v-if="getCountryPostageFee"
                                >
                                    Postage Fee for
                                    <em class="text-primary">{{ getCountryPostageFee.name }}</em>: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ getCountryPostageFee.postage_fee | formatPrice }} {{ currency }}
                                        </em>
                                    <br>
                                </h3> -->

                                <hr class="my-4">
                                <h3>
                                    Total: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ totalAmount | formatPrice }} {{ currency }}
                                    </em>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-12 text-center" v-if='showErrorStatus'>
                    <base-alert :type="errorType">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{ errorMsg }}
                        </span>
                    </base-alert>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4">
                </div>


                <div class="col-lg-12" v-if="! showPaymentForm">
                    <base-button
                        type="success"
                        @click="createApplication()"                        
                    >
                        <i class="fa fa-save"></i>
                        {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                    </base-button>
                </div>

                <div class="col-lg-12" v-if="showPaymentForm">                    
                    <base-button
                        type="success"
                        class="right"
                        @click="loadPaymentFunction()"
                    >
                        <i class="fa fa-credit-card"></i>                            
                        {{ isloading ? 'Please Wait...' : 'Pay Now'}}
                    </base-button>

                    <flutterwave-pay-button
                        id="flutter-pay"
                        :tx_ref="orderId"
                        :amount="totalAmount"
                        :currency="currency"
                        payment_options="card"
                        redirect_url=""
                        class="btn btn-success"
                        style=""
                        :meta="paymentRecords.meta"
                        :customer="paymentRecords.customer"
                        :customizations="paymentRecords.customizations"
                        :callback="makePaymentCallback"
                        :onclose="closedPaymentModal"
                        hidden
                    >
                        <i class="fa fa-credit-card"></i>                            
                        {{ isloading ? 'Please Wait...' : 'Pay Now'}}
                    </flutterwave-pay-button>
                </div>

            </div>
        </div>

    </div>



</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { FlutterwavePayButton } from "flutterwave-vue-v3";

    export default {
        name: 'DiasporaRegistrationPayment',
        props: ['application', 'user', 'serviceType', 'hasPreviousApplication', 'previousApplications', 'applicationType', 'loadApplication', 
                'displayNewPayment', 'changeAppId'],
        components: {
            FlutterwavePayButton
        },
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showPaymentForm: false,
                orderId: null,
                id: null
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' ';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations']),
            currency() {// get application currency
                return this.applicationType && this.applicationType.has_payment && this.applicationType.has_payment.currency 
                        ? this.applicationType.has_payment.currency : 'NGN';
            },
            totalAmount() {// get total amount
                let amount = parseInt(this.application.has_payment.amount);
                return this.getPostageFee() ? (parseInt(this.getPostageFee().postage_fee) + amount) : amount;
            },
            getCountryPostageFee() {// get postage fee for full without provisional internship
                if(this.previousApplications && this.previousApplications.length > 0) {
                    let app = this.previousApplications[0];
                    if(app.postage_fee) {
                        return app.postage_fee;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            basicProfile() {// get user data
                return this.user && this.user.basic_profile ? this.user.basic_profile : {}
            },
            fullName() {// get user's full name
                let name = '';
                if(this.basicProfile) {
                    name += this.basicProfile.first_name;
                    name += this.basicProfile.other_name ? ' ' +  this.basicProfile.other_name : '';
                    name += ' ' + this.basicProfile.last_name;
                }
               return name;
            },
            paymentRecords() {
                return {
                    meta : {
                        counsumer_id: (this.basicProfile ? this.basicProfile.id : ''),
                        // consumer_mac: "kjs9s8ss7dd"
                    },
                    customer: {
                        name: this.fullName,
                        email: (this.user ? this.user.email : ''),
                        phone_number: (this.basicProfile ? this.basicProfile.phone : '')
                    },
                    customizations: {
                        title: "MDCN Global Network",
                        description: "Global Network",
                        logo: "https://www.portal.mdcn.gov.ng/img/brand/white.png"
                    }
                };                
            }            
        },
        methods: {
            ...mapActions(['createNewUserApplication', 'updateDiasporaAppTransaction', 'updateTransNo']),
            loadPaymentFunction() {
                const self = this;
                self.isloading = true;
                setTimeout(function() { self.isloading = false; }, 5000);
                $("#flutter-pay").click();
            },
            makePaymentCallback(res) {
                if(res.status == "successful") {// payment was successful
                    this.preloader(); // loading....
                    this.isloading = true;
                    let data = {
                        orderId : this.orderId,
                        status : res.charge_response_code,
                        amount : res.amount,
                        transactiontime : res.created_at,
                        paymentDate : res.created_at,
                        message : res.charge_response_message,
                        RRR : res.transaction_id,
                        currency : this.currency
                    };   
                    this.updateDiasporaAppTransaction(data).then((res) => {// create application
                        this.loadApplication();
                        this.isloading = false;
                        if(res.status) {// update transaction                            
                            swal.fire('Payment Successful!', res.msg, 'success');
                        } else {
                            swal.fire('Error Occurred', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'An error occurred while adding payment, kindly requery transaction before making another payment';
                        swal.fire('Error Occurred', message, 'warning');
                        return false;
                    });
                } else {
                    swal.fire('Payment Failed!', 'Payment wasn\'t succesfull, kindly try again...', 'warning');
                }
            },
            closedPaymentModal() {
                // console.log('payment modal is closed');
            },          
            getPostageFee() {// get postage fee for full without provisional internship
                if(this.previousApplications && this.previousApplications.length > 0) {
                    let app = this.previousApplications[0];
                    if(app.postage_fee) {
                        return app.postage_fee;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            loadPreviousDetails() {
                let application = this.previousApplications && this.previousApplications.length > 0
                        ? this.previousApplications[0] : null;
                this.orderId = application ? application.trans_no : null;
                this.id = application ? application.id : null;
            },
            showMsg(type, msg) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            createApplication() {// create application
                this.preloader();// show loading
                if(this.orderId) {
                    this.updateTransNo({'id' : this.id}).then((res) => {//update trans no
                        this.changeAppId(res.application.id, res.application_type, res.service_type);// apply changes to application type
                        this.displayNewPayment(true);// show new payment blade
                        this.orderId = res.trans_no;
                        this.showPaymentForm = true;
                        let message = 'Kindly click on Pay Now to make payment online';
                        swal.fire('Make Payment Now!', message, 'success');
                    }).
                    catch(() => {
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                     return this.createNewApplication(); // create new application
                }
            },
            createNewApplication() {
                let data = {};
                data.type = this.applicationType;// get exact application type
                data.user_data_id = this.$store.state.user.userDataId;// get user data id
                data.application_type_id = this.application.id;// apllication type id
                data.trans_no = this.genRandomString();// generate trans no
                data.application_date = this.getCurrentDate();// get current date
                this.createNewUserApplication(data).then((res) => {// create application
                    let app = res.application ? res.application : res.license;// get valid application
                    this.changeAppId(app.id, res.type, res.service_type);// apply changes to application type
                    if(res.status) {// update transaction
                        this.showPaymentForm = true;
                        this.orderId = data.trans_no;
                        let message = 'Kindly click on Pay Now to make payment online';
                        swal.fire('Make Payment Now!', message, 'success');
                    }
                }).
                catch(() => {
                    let message = 'While creating application, kindly reload and try again';
                    swal.fire('Error ocurred', message, 'warning');
                    return false;
                });
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadPreviousDetails();// get previous application details
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>