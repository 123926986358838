import axios from 'axios';

const perPage = 20;

const state = {
    user: {},
    basicProfile: {},
    accessToken: localStorage.getItem('access_token') || null,
    userId: localStorage.getItem('user_id') || null,
    userDataId: localStorage.getItem('user_data_id') || null,
    locations: {},
    specializations: {},
    userDocuments: {},
    uploadProgress: 10,
    jurisdictions: [],
    perPage: perPage,
    dashbardInfo : {},
    notifications: {},
    hospitals: []
};

const getters = {
    basicProfile: state => state.basicProfile,
    user: state => state.user,
    getUserAccessToken: state => state.accessToken,
    locations: state => state.locations,
    specializations: state => state.specializations,
    lock: state => (state.basicProfile.profile_lock == 'yes') ? true : false,
    jurisdictions: state => state.jurisdictions,
    isLoggedIn: state => state.accessToken !== null && state.userId !== null ? true : false,
    dashbardInfo: state => state.dashbardInfo,
    notifications: state => state.notifications,
    hospitals: state => state.hospitals
};

const actions = {
    async fetchUserProfile(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.profileUserUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.userId},
                { headers: header });// make api call
        state.commit('setUserProfile', response.data.basic_profile);
        state.commit('setUser', response.data.user);
        state.commit('setDashboardInfo', response.data.dashboard_info);// set dashboard info
        state.commit('setLocations', response.data.locations); // set locations
        return response.data.user;
    },
    destroyAccessToken(state) {// destroy local storage
        localStorage.removeItem('access_token');// remove token
        localStorage.removeItem('user_id');// remove user id
        localStorage.removeItem('user_data_id');// remove user data id
        localStorage.removeItem('time_out');// remove time out
        return state.accessToken = null;
    },
    storeAndLoginUser(state, data) {//set token and login user
        let hours = 2;
        localStorage.setItem('access_token', data.access_token);// set access token
        localStorage.setItem('user_id', data.user_id);// set user id
        localStorage.setItem('user_data_id', data.user_data_id);// set user data id
        localStorage.setItem('time_out', new Date().getTime() + (hours * 60 * 60 * 1000));// set timeout
    },
    async getBasicProfile(state) {// fetch user profile function
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.profileUserUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.userId},
                        { headers: header });// make api call
        return response.data.basic_profile;
    },
    async getLocations(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.locationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        state.commit('setLocations', response.data);
        return response.data;
    },
    async UpdateUserProfile(state, data) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data}, { headers: header });// make api call
        state.commit('setUserProfile', response.data);// set user profile function
        return response.data;
    },
    async getSpecializations(state) {// fetch specializations & sub specializations
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.specializationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        state.commit('setSpecializations', response.data);
        return response.data;
    },
    async UpdateUserWorkProfile(state, data) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserWorkUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data}, { headers: header });// make api call
        return response.data;
    },
    async getUserDocuments(state, regId) {// fetch user docs
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.userId,
                        'reg_id' : regId }, { headers: header });// make api call
        state.commit('setUserDocuments', response.data);// set user profile function
        return response.data;
    },
    async uploadUserDocuments(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadUserDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async uploadUserPhoto(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadUserPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    updatePhotoName({ commit }, fileName) {// update photo name
        commit('setUpdatePhotoName', fileName);// set update photo function
    },
    async saveJurisdictions(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveJurisdictionsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async removeJurisdiction(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.removeJurisdictionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async saveReferees(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveRefereesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async removeReferee(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.removeRefereeUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async updateUserAdditionalRecords(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateAdditionalRecordsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header });// make api call
        return response.data;
    },
    async getUserAdditionalRecords(state, data) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAdditionalRecordsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUpdateProfileInfo(state) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUpdateProfileInfoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : state.state.userId}, { headers: header});// make api call
        return response.data;
    },
    async getRegistrationTypes(state) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRegistrationTypesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getUserSubRegistrationTypes(state) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSubRegistrationTypesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getDashboardInfo(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDashboardInfoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.userId},
                        { headers: header });// make api call
        state.commit('setDashboardInfo', response.data);
    },
    async changePassword(state, data) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changePasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUserPhoto(state) {// fetch user profile function
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.userId},
                        { headers: header });// make api call
        return response.data;
    },
    async getUserCertificates(state, limit) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserCertificatesUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {id : id, limit : limit}, { headers: header});// make api call
        return response.data;
    },
    async disableProfile(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.disableProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.userDataId},
                { headers: header });// make api call
        return response.data;
    },
    async validatingFolio(state, folio) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.validatingFolioUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'folio_number' : folio}, { headers: header});// make api call
        return response.data;
    },
    async getDoctorStatusProfile(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDoctorStatusProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getUserNotifications(state, limit) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.userDataId, limit : limit }, { headers: header });// make api call
        state.commit('setUserNotifications', response.data);
        return response.data;
    },
    async deleteUserNotification(state, id) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserNotificationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async getUserNotification(state, slug) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.userDataId, slug : slug }, { headers: header });// make api call
        return response.data;
    },
    async verifyRecaptcha(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.verifyRecaptchaUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getHospitals(state) {// fetch Hospitals 
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        state.commit('setHospitals', response.data);
        return response.data;
    },
    async getUserPendingUploads(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserPendingUploadsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {id : state.state.userId}, { headers: header});// make api call
        return response.data;
    },
    async updateUserForeignAssociation(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserForeignAssociationUrl;
        data.id = state.rootState.user.userDataId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    }
};

const mutations = {
    setUserProfile: (state, userData) => (state.basicProfile = userData),// set user basic profile only
    setUser: (state, user) => (state.user = user),// set user profile
    setLocations: (state, data) => (state.locations = data),// set locations (states, lgas, and countries)
    setSpecializations: (state, data) => (state.specializations = data),// set specializations
    setUserDocuments: (state, data) => (state.userDocuments = data),// set user documents
    setUpdatePhotoName: (state, fileName) => (state.basicProfile.photo = fileName),// set user basic profile only
    setDashboardInfo: (state, data) => (state.dashbardInfo = data),// set user profile
    setUserNotifications(state, data) {// set user notifications
        state.notifications.records = data.notifications.data;
        state.notifications.total = data.total;
    },
    setHospitals: (state, data) => {
        let hospitals = data.hospitals;
        let other = { id : 0, name: 'Others (Specify)'};
        hospitals.unshift(other);
        state.hospitals = hospitals;
    },// set hospitals 
};

export default {
    state,
    getters,
    actions,
    mutations
}