<template>
   <div>
        <div class="row" v-if="showAssociationsPage">
            <div class="col-lg-12">
                <h3 class="text-center text-uppercase text-danger">kindly select below the Association you belong to below... </h3>
            </div>
            <div class="col-lg-12"><hr></div>
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <base-input alternative=""
                    label="Select Your Association"
                    required
                >
                    <autocomplete
                        v-if="associations && associations.length > 0"
                        input-class="form-control form-control-alternative" 
                        placeholder="Type to Search for Associations"
                        showNoResults
                        :source="associations"
                        v-model="record.association_id"
                        @selected="saveUserAssociation"
                        :initialDisplay="(hasAssociation ? hasAssociation.name : '')"
                    >
                    </autocomplete>
                </base-input>
            </div>
        </div>

        <div class="row" v-if="! showAssociationsPage && isToVerifyMembership">
            <div class="col-lg-12"> 
                <hr>

                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h3 class="text-uppercase text-danger">
                            We need to verify your membership status with 
                            <em class="text-primary">{{ hasAssociation ? hasAssociation.code : 'your Association' }}</em>. 
                            Please kindly enter the registered email address used on 
                            <em class="text-primary">{{ hasAssociation ? hasAssociation.code : 'your Association' }}</em>'s portal 
                        </h3>
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4 text-center">
                        <base-input alternative=""
                            label="Email Address"
                            placeholder="Enter a valid Email Address"
                            input-classes="form-control-alternative"
                            v-model="record.email"
                            required
                        />
                    </div>

                    <div class="col-lg-12 text-center">
                        <base-button type="success" @click="$emit('verifyMembership')">
                            <i class="fa fa-history" @if='! isloading'></i>
                            {{isloading ? 'Please Wait...' : 'Verify Membership Now'}}
                        </base-button>

                        <base-button type="danger" @click="changeAssociation">
                            <i class="fa fa-times"></i>
                            Change Association
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
   </div>

</template>

<script>
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AssociationPage',
        props: ['associations', 'record', 'saveUserAssociation', 'hasAssociation', 'isToVerifyMembership', 'isloading', 'showAssociationsPage', 
                    'changeAssociation'],
        components: {
            Autocomplete            
        }              
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>