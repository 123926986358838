<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <div class="col-lg-12">
                <h6 class="heading-small text-muted mb-4">
                    Upload Passport Photograph &nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="text-danger">
                        <i class="fa fa-info-circle"></i> Only Image Files (Less than 500KB) are supported
                    </span>
                </h6>
                <br><br>
            </div>

            <div class="col-lg-12"></div>


            <div class="col-lg-12">
                <div class="card card-profile shadow">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                                <a 
                                    href="javascript:void(0)"
                                    @click="$refs.photo.click()"
                                >
                                    <img
                                        v-if="file.has_file"
                                        :src="userPhotoUrl+file.file_name"
                                        class="rounded-circle image-size"
                                    >
                                    <img
                                        v-if="! file.has_file"
                                        :src="blankImageUrl"
                                        class="rounded-circle image-size"
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <div class="d-flex justify-content-between">
                            <!-- <base-button size="sm" type="info" class="mr-4">Connect</base-button>
                            <base-button size="sm" type="default" class="float-right">Message</base-button> -->
                        </div>
                    </div>
                    <div class="card-body pt-0 pt-md-4">
                        <div class="row">
                            <div class="col">
                                <div class="card-profile-stats d-flex justify-content-center mt-md-5">

                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <input
                                type="file"
                                ref="photo"
                                style="display: none"
                                id="passport"
                                @change="onFileSelected"
                            >
                            <h3>
                                <base-progress
                                    v-if="loadPercent.show"
                                    :type="loadPercent.type"
                                    :height="10"
                                    :value="loadPercent.value"
                                    :striped="true"
                                    :animated="loadPercent.animated"
                                >
                                </base-progress>
                                <h4 class="text-danger">
                                    Note that you are to upload a passport photograph or else your application will be rejected 
                                    (Your face and jaws must be displayed)
                                </h4>
                                <base-button
                                    size="sm" type="info"
                                    class="mr-4 marginLeft30"
                                    v-if="! loadPercent.show"
                                    @click="$refs.photo.click()"
                                >
                                    <i class="fas fa-pen"></i>
                                    Click to change Photo
                                </base-button>
                            </h3>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="my-4"/>

        <div class="pl-lg-4">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
            <div class="right" v-if="hasPhoto">
                <base-button
                    type="success"
                    @click="showNextPage()"
                >
                    Continue
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>


    </div>


</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ProfilePhoto',
        props: ['displayAppointments', 'displayDocuments'],
        data() {
            return {
                savedEvent: null,
                showUploadButton: false,
                photo: {
                    id: null,
                    ext: null,
                    file: null
                },
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                },
                isLoadingBlade: true,
                file: {}
            }
        },
        computed: {
            ...mapGetters(['blankImageUrl', 'loadingBladeUrl', 'userPhotoUrl']),
            hasPhoto() {
                return this.file.has_file;
            }
        },
        methods: {
            ...mapActions(['uploadUserPhoto', 'getUserPhoto', 'updatePhotoName']),
            loadUserPhoto() {// get user profile
                this.getUserPhoto().then((res) => {
                    this.isLoadingBlade = false;
                    this.file = res;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            showPreviousPage() {// go to next page
                return this.displayAppointments(true);
            },
            showNextPage() {// go to next page
                return this.displayDocuments(true);
            },
            makeUploadFieldsEmpty() {
                this.photo.id = null;
                this.photo.ext = null;
                this.photo.file = null;
            },
            onFileSelected(event) {
                this.savedEvent = null;
                this.makeUploadFieldsEmpty();
                this.savedEvent = event;
                let status =  this.validateFile(event.target.files[0]);
                return status ? this.uploadPhoto() : false;
            },
            uploadPhoto() {
                this.photo.id = this.$store.state.user.userId;// get user id
                let event = this.savedEvent;// get store file event
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    this.photo.file = event.target.result;
                    let img = new Image();
                    img.src = window.URL.createObjectURL(this.savedEvent.target.files[0]);
                    img.onload = () => {
                        var height = img.height;
                        var width = img.width;
                        if ((width <= 500) && (height <= 500)) {
                            this.loadPercent.show = true;
                            this.makeUploadApiCall();// upload photo
                        } else {
                            let msg = "Width and Height must not exceed 500 * 500 pixels";
                            swal.fire("Invalid Width/Height!", msg, "warning");
                            return false;
                        }
                    }
                }
            },
            makeUploadApiCall() {
                this.uploadUserPhoto(this.photo).then((res) => {
                    this.uploadPercent(this.$store.state.user.uploadProgress);// show load progress
                    var self = this;
                    self.makeUploadFieldsEmpty();
                    self.savedEvent = null;// eslint-disable-next-line
                    $('#passport').val('');
                    if(res.status) {
                        self.updatePhotoName(res.file_name);// update file name
                        this.loadPercent.show = false;
                        this.loadUserPhoto();// load photo
                        swal.fire("Photo Uploaded!", "Profile Photo was uploaded successfully", "success");
                    } else {
                        self.loadPercent.show = false;
                        let msg = res && res.msg ? res.msg : "An error occurred while uploading, try again";
                        swal.fire("Error Occurred!", msg, "warning");
                    }
                }).
                catch(err => {
                    var self = this;
                    // self.makeUploadFieldsEmpty();
                    self.savedEvent = null;
                    swal.fire(err.message+"!", "Reduce file size and try again...", "warning");
                    self.loadPercent.show = false;// eslint-disable-next-line
                    $('#passport').val('');
                });
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            validateFile(file) {
                if(!file) { this.savedEvent = null; }// update seleted file
                const validFileTypes = ['jpg', 'jpeg', 'JPEG', 'JPG', 'png', 'PNG'];// valid image format array
                var fileExt = file.type.split('/').pop();// get selected file ext
                this.photo.ext = fileExt;
                var validExt = validFileTypes.includes(fileExt);
                let fileSize = file.size;// get selected file size
                if(validExt && fileSize <= 500000) {// for valid file and size
                    return true;
                } else if(! validExt) {// invalid file
                    this.savedEvent = null;
                    swal.fire("Invalid File!", "Only JPG/PNG files are accepted", "warning");
                    return false;
                } else if(fileSize > 500000) {// big file
                    this.savedEvent = null;
                    swal.fire("File is too large!", "File should be less than 500KB", "warning");
                    return false;
                }
            }
        },
        created() {
            this.loadUserPhoto();// get user photo
        },
    }
</script>

<style>
.marginLeft30 {
    margin-left: 30px;
}
</style>